.icon {
  &.icon-chevron-down {
    padding: 0 .438rem;
    background-image: escape-svg($icon-chevron-down);
    background-repeat: $icon-bg-repeat;
    background-position: $icon-bg-position;
  }

  &.icon-chevron-up {
    padding: 0 .438rem;
    background-image: escape-svg($icon-chevron-up);
    background-repeat: $icon-bg-repeat;
    background-position: $icon-bg-position;
  }

  &.icon-info {
    padding: $icon-padding;
    background-image: escape-svg($icon-info);
    background-repeat: $icon-bg-repeat;
    background-position: $icon-bg-position;
    margin: $icon-margin;
  }

  &.icon-warning {
    padding: $icon-padding;
    background-image: escape-svg($icon-warning);
    background-repeat: $icon-bg-repeat;
    background-position: $icon-bg-position;
    margin: $icon-margin;
  }

  &.icon-danger {
    padding: $icon-padding;
    background-image: escape-svg($icon-danger);
    background-repeat: $icon-bg-repeat;
    background-position: $icon-bg-position;
    margin: $icon-margin;
  }

  &.icon-success {
    padding: $icon-padding;
    background-image: escape-svg($icon-success);
    background-repeat: $icon-bg-repeat;
    background-position: $icon-bg-position;
    margin: $icon-margin;
  }
}
