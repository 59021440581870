@font-face{
  font-family: "Zoram";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/Zoram-GWebM-Light-Normal.woff2") format("woff2"), url("../fonts/Zoram-GWebM-Light-Normal.woff") format("woff");
}

@font-face{
  font-family: "Zoram";
  font-weight: 300;
  font-style: italic;
  src: url("../fonts/Zoram-GWebM-Light-Italic.woff2") format("woff2"), url("../fonts/Zoram-GWebM-Light-Italic.woff") format("woff");
}

@font-face{
  font-family: "Zoram";
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/Zoram-GWebM-Regular-Normal.woff2") format("woff2"), url("../fonts/Zoram-GWebM-Regular-Normal.woff") format("woff");
}

@font-face{
  font-family: "Zoram";
  font-weight: 400;
  font-style: italic;
  src: url("../fonts/Zoram-GWebM-Regular-Italic.woff2") format("woff2"), url("../fonts/Zoram-GWebM-Regular-Italic.woff") format("woff");
}

// @font-face
//     font-family: "Zoram"
//     font-weight: 500
//     font-style: normal
//     src: url("../fonts/Zoram-GWebM-SemiBold-Normal.woff2") format("woff2"), url("../fonts/Zoram-GWebM-SemiBold-Normal.woff") format("woff")

@font-face{
  font-family: "Zoram";
  font-weight: 600;
  font-style: normal;
  src: url("../fonts/Zoram-GWebM-SemiBold-Normal.woff2") format("woff2"), url("../fonts/Zoram-GWebM-SemiBold-Normal.woff") format("woff");
}

@font-face{
  font-family: "Zoram";
  font-weight: 600;
  font-style: italic;
  src: url("../fonts/Zoram-GWebM-SemiBold-Italic.woff2") format("woff2"), url("../fonts/Zoram-GWebM-SemiBold-Italic.woff") format("woff");
}

@font-face{
  font-family: "Zoram";
  font-weight: 700;
  font-style: normal;
  src: url("../fonts/Zoram-GWebM-Bold-Normal.woff2") format("woff2"), url("../fonts/Zoram-GWebM-Bold-Normal.woff") format("woff");
}

@font-face{
  font-family: "Zoram";
  font-weight: 700;
  font-style: italic;
  src: url("../fonts/Zoram-GWebM-Bold-Italic.woff2") format("woff2"), url("../fonts/Zoram-GWebM-Bold-Italic.woff") format("woff");
}

// @font-face
//     font-family: "Zoram"
//     font-weight: 800
//     font-style: normal
//     src: url("../fonts/Zoram-GWebM-ExtraBold-Normal.woff2") format("woff2"), url("../fonts/Zoram-GWebM-ExtraBold-Normal.woff") format("woff")

// @font-face
//     font-family: "Zoram"
//     font-weight: 800
//     font-style: italic
//     src: url("../fonts/Zoram-GWebM-ExtraBold-Italic.woff2") format("woff2"), url("../fonts/Zoram-GWebM-ExtraBold-Italic.woff") format("woff")

@font-face{
  font-family: "McKay";
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/McKay-GWebM-Regular-Normal.woff2") format("woff2"), url("../fonts/McKay-GWebM-Regular-Normal.woff") format("woff");
}

@font-face{
  font-family: "McKay";
  font-weight: 400;
  font-style: italic;
  src: url("../fonts/McKay-GWebM-Regular-Italic.woff2") format("woff2"), url("../fonts/McKay-GWebM-Regular-Italic.woff") format("woff");
}

// @font-face
//     font-family: "McKay"
//     font-weight: 500
//     font-style: normal
//     src: url("../fonts/McKay-GWebM-Regular-Normal.woff2") format("woff2"), url("../fonts/McKay-GWebM-Regular-Normal.woff") format("woff")

// @font-face
//     font-family: "McKay"
//     font-weight: 500
//     font-style: italic
//     src: url("../fonts/McKay-GWebM-Regular-Italic.woff2") format("woff2"), url("../fonts/McKay-GWebM-Regular-Italic.woff") format("woff")

@font-face{
  font-family: "McKay";
  font-weight: 700;
  font-style: normal;
  src: url("../fonts/McKay-GWebM-Bold-Normal.woff2") format("woff2"), url("../fonts/McKay-GWebM-Bold-Normal.woff") format("woff");
}

@font-face{
  font-family: "McKay";
  font-weight: 700;
  font-style: italic;
  src: url("../fonts/McKay-GWebM-Bold-Italic.woff2") format("woff2"), url("../fonts/McKay-GWebM-Bold-Italic.woff") format("woff");
}

.font-zoram{
  font-family: "Zoram",Roboto,-apple-system,BlinkMacSystemFont,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.font-mcKay{
  font-family: McKay;
}
