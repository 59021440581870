//
// Base styles
//

.bar {
  height: $bar-height;
  width: $bar-width;
  background-color: $bar-bg-color;
}

.bar-lg {
  width: $bar-lg-width !important;
}

// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

@each $color, $value in $theme-colors {
  .bar-#{$color} {
    @include bar-variant($color);
  }
}
