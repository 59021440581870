//
// Base styles
//

.notification {
  // scss-docs-start notification-css-vars
  --#{$prefix}notification-bg: transparent;
  --#{$prefix}notification-padding-x: #{$notification-padding-x};
  --#{$prefix}notification-padding-y: #{$notification-padding-y};
  --#{$prefix}notification-margin-bottom: #{$notification-margin-bottom};
  --#{$prefix}notification-color: inherit;
  --#{$prefix}notification-border-color: transparent;
  --#{$prefix}notification-border: #{$notification-border-width} solid var(--#{$prefix}notification-border-color);
  --#{$prefix}notification-border-radius: #{$notification-border-radius};
  // scss-docs-end notification-css-vars

  position: relative;
  padding: var(--#{$prefix}notification-padding-y) var(--#{$prefix}notification-padding-x);
  margin-bottom: var(--#{$prefix}notification-margin-bottom);
  color: var(--#{$prefix}notification-color);
  background-color: $notification-bg;
  border: var(--#{$prefix}notification-border);
  @include border-radius(var(--#{$prefix}notification-border-radius));

  > p {
    margin: 0;
    text-align: center;
  }
}

// Headings for larger notifications
.notification-heading {
  // Specified to prevent conflicts of changing $headings-color
  color: inherit;
}

// Provide class for links that match notifications
.notification-link {
  font-weight: $notification-link-font-weight;
}


// Dismissible notifications
//
// Expand the right padding and account for the close button's positioning.

.notification-dismissible {
  padding-right: $notification-dismissible-padding-r;

  // Adjust close link position
  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: $stretched-link-z-index + 1;
    padding: $notification-padding-y * 1.25 $notification-padding-x;
  }
}


// scss-docs-start notification-modifiers
// Generate contextual modifier classes for colorizing the notification.

@each $state, $value in $theme-colors {
  $notification-border: $value;
  $notification-link-color: $notification-link-font-color;

  .notification-#{$state} {
    @include notification-variant($notification-border, $notification-link-color);
  }
}
// scss-docs-end notification-modifiers
