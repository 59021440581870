.monogram {
  height: 4rem;
  width: 4rem;
  color: $white;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 60px;

  &.monogram-sm {
    height: 2rem !important;
    width: 2rem !important;
    font-size: 0.875rem !important;
    line-height: 30px !important;
    font-weight: 400 !important;
  }

  &.monogram-lg {
    height: 6rem !important;
    width: 6rem !important;
    font-size: 2.625rem !important;
    line-height: 90px !important;
  }

  &.monogram-primary {
    background-image: escape-svg($monogram-primary);
    background-size: contain;
    background-repeat: no-repeat;
  }

  &.monogram-warning {
    background-image: escape-svg($monogram-warning);
    background-size: contain;
    background-repeat: no-repeat;
  }

  &.monogram-danger {
    background-image: escape-svg($monogram-danger);
    background-size: contain;
    background-repeat: no-repeat;
  }

  &.monogram-success {
    background-image: escape-svg($monogram-success);
    background-size: contain;
    background-repeat: no-repeat;
  }

  &.monogram-gray {
    background-image: escape-svg($monogram-gray);
    background-size: contain;
    background-repeat: no-repeat;
  }
}