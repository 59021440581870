@mixin bar-variant($color) {
  //@error "Debug out: `#{$color}`";

  @if($color == primary){
    background-color: $churchInfo40;
  }@else if($color == warning){
    background-color: $churchWarning40;
  }@else if($color == success){
    background-color: $churchConfirm40;
  }@else if($color == danger){
    background-color: $churchDanger40;
  }@else{
    background-color: $churchInfo40;
  }

}
