// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

// Church colors
/*Blue*/
$churchInfoHighlight: #e9f7fc !default;
$churchInfo10: #20abd7 !default;
$churchInfo40: #177c9c !default;
$churchInfo60: #105970 !default;

/*Red*/
$churchDangerHighlight: #fee8e7 !default;
$churchDanger10: #fc7473 !default;
$churchDanger40: #e63939 !default;
$churchDanger60: #b00504 !default;

/*Yellow*/
$churchWarningHighlight: #fff8e9 !default;
$churchWarning10: #ffd072 !default;
$churchWarning40: #ffae0c !default;
$churchWarning60: #8f4200 !default;

/*Green*/
$churchConfirmHighlight: #eff7ea !default;
$churchConfirm10: #a3d287 !default;
$churchConfirm40: #74bb49 !default;
$churchConfirm60: #375a22 !default;

/*Greys*/
$churchGray02: #f7f8f8 !default;
$churchGray03: #eff0f0 !default;
$churchGray05: #e0e2e2 !default;
$churchGray10: #d0d0d3 !default;
$churchGray15: #bdc0c0 !default;
$churchGray20: #a9adad !default;
$churchGray25: #9da1a1 !default;
$churchGray30: #878a8c !default;
$churchGray35: #676b6e !default;
$churchGray40: #53575b !default;
$churchGray60: #3a3d40 !default;
$churchGray90: #0d0f10 !default;
$churchText120: #212225 !default;

/*Highlight colors*/
$churchHighlightBlue: #dff0ff !default;
$churchHighlightCyan: #ddfafb !default;
$churchHighlightGreen: #eff7ea !default;
$churchHighlightYellow: #fef8d9 !default;
$churchHighlightAmber: #ffefde !default;
$churchHighlightRed: #ffe8eb !default;
$churchHighlightMagenta: #fee8fc !default;
$churchHighlightViolet: #f3e9ff !default;

//End church colors

// scss-docs-start gray-color-variables
$white:    #fff !default;
$gray-100: $churchGray02;
$gray-200: $churchGray03;
$gray-300: $churchGray05;
$gray-400: $churchGray10;
$gray-500: $churchGray15;
$gray-1000: $churchGray20;
$gray-700: $churchGray25;
$gray-800: $churchGray30;
$gray-900: $churchGray35;
$gray-1000: $churchGray40;
$gray-1100: $churchGray60;
$gray-1200: $churchGray90;
$black:    #000 !default;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-1000,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900,
  "1000": $gray-1000,
  "1100": $gray-1100,
  "1200": $gray-1200
) !default;
// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start color-variables
$blue:    $churchInfo40;
$red:     $churchDanger60;
$yellow:  $churchWarning10;
$green:   $churchConfirm40;
// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: (
  "blue":       $blue,
  "red":        $red,
  "yellow":     $yellow,
  "green":      $green,
  "black":      $black,
  "white":      $white,
  "gray":       $gray-1000,
  "gray-dark":  $gray-800
) !default;
// scss-docs-end colors-map

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio:   4.5 !default;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark:      $black !default;
$color-contrast-light:     $white !default;

// scss-docs-start theme-color-variables
$primary:       $blue !default;
$secondary:     $gray-1000 !default;
$success:       $green !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-1000 !default;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary":        $primary,
  "secondary":      $secondary,
  "success":        $success,
  "warning":        $warning,
  "danger":         $danger,
  "light":          $light,
  "dark":           $dark,
  "primary-ui":     $churchInfo10,
  "info-ui":        $churchInfo10,
  "success-ui":     $churchConfirm40,
  "warning-ui":     $churchWarning40,
  "danger-ui":      $churchDanger40
) !default;
// scss-docs-end theme-colors-map

// scss-docs-start theme-ui-colors-map
$theme-ui-colors: (
  "info":       $churchInfo10,
  "success":    $churchConfirm40,
  "warning":    $churchWarning40,
  "danger":     $churchDanger40
) !default;
// scss-docs-end theme-ui-colors-map

// Characters which are escaped by the escape-svg function
$escaped-characters: (
  ("<", "%3c"),
  (">", "%3e"),
  ("#", "%23"),
  ("(", "%28"),
  (")", "%29"),
) !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret:                true !default;
$enable-rounded:              true !default;
$enable-shadows:              true !default;
$enable-gradients:            false !default;
$enable-transitions:          true !default;
$enable-reduced-motion:       true !default;
$enable-smooth-scroll:        true !default;
$enable-grid-classes:         true !default;
$enable-container-classes:    true !default;
$enable-cssgrid:              false !default;
$enable-button-pointers:      true !default;
$enable-rfs:                  true !default;
$enable-validation-icons:     true !default;
$enable-negative-margins:     false !default;
$enable-deprecation-messages: true !default;
$enable-important-utilities:  true !default;

// Prefix for :root CSS variables

$variable-prefix:             bs- !default; // Deprecated in v5.2.0 for the shorter `$prefix`
$prefix:                      $variable-prefix !default;

// Gradient
//
// The gradient which is added to components if `$enable-gradients` is `true`
// This gradient is also added to elements with `.bg-gradient`
// scss-docs-start variable-gradient
$gradient: linear-gradient(180deg, rgba($white, .15), rgba($white, 0)) !default;
// scss-docs-end variable-gradient

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
) !default;
// scss-docs-end spacer-variables-maps

// Position
//
// Define the edge positioning anchors of the position utilities.

// scss-docs-start position-map
$position-values: (
  0: 0,
  50: 50%,
  100: 100%
) !default;
// scss-docs-end position-map


// Bar
$bar-bg-color:                      $gray-800 !default;
$bar-height:                        .25rem !default;
$bar-width:                         2.5rem !default;

$bar-lg-bg-color:                   $gray-800 !default;
$bar-lg-height:                     .25rem !default;
$bar-lg-width:                      3.5rem !default;

//Monograms
$monogram-gray:             url("data:image/svg+xml,<svg width='96' height='96' viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='48' cy='48' r='48' fill='#676B6E'/><mask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='96' height='96'><circle cx='48' cy='48' r='48' fill='#006184'/></mask><g mask='url(#mask0)'><path fill-rule='evenodd' clip-rule='evenodd' d='M-66 -10V3.29202L141.486 43.1681V-10H-66Z' fill='url(#paint0_linear)'/><path fill-rule='evenodd' clip-rule='evenodd' d='M-66 -10V123.575L121.133 -10H-66Z' fill='url(#paint1_linear)'/><path fill-rule='evenodd' clip-rule='evenodd' d='M-66 -10V70.4069L141.486 -10H-66Z' fill='url(#paint2_linear)'/><path fill-rule='evenodd' clip-rule='evenodd' d='M-66 3.3575H141.486V-10H-66V3.3575Z' fill='url(#paint3_linear)'/></g><defs><linearGradient id='paint0_linear' x1='-66' y1='-10' x2='-66' y2='43.1681' gradientUnits='userSpaceOnUse'><stop stop-color='#A9ADAD' stop-opacity='0.6'/><stop offset='1' stop-color='#A9ADAD' stop-opacity='0.25'/></linearGradient><linearGradient id='paint1_linear' x1='-66' y1='-10' x2='-66' y2='123.575' gradientUnits='userSpaceOnUse'><stop stop-color='#A9ADAD' stop-opacity='0.35'/><stop offset='1' stop-color='#A9ADAD' stop-opacity='0.15'/></linearGradient><linearGradient id='paint2_linear' x1='-66' y1='-10' x2='-66' y2='70.4069' gradientUnits='userSpaceOnUse'><stop stop-color='#A9ADAD' stop-opacity='0.6'/><stop offset='1' stop-color='#A9ADAD' stop-opacity='0.25'/></linearGradient><linearGradient id='paint3_linear' x1='-66' y1='3.3575' x2='141.486' y2='3.3575' gradientUnits='userSpaceOnUse'><stop stop-color='#A9ADAD' stop-opacity='0.6'/><stop offset='1' stop-color='#A9ADAD' stop-opacity='0.35'/></linearGradient></defs></svg>") !default;
$monogram-primary:          url("data:image/svg+xml,<svg width='96' height='96' viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='48' cy='48' r='48' fill='#006184'/><mask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='96' height='96'><circle cx='48' cy='48' r='48' fill='#006184'/></mask><g mask='url(#mask0)'><path fill-rule='evenodd' clip-rule='evenodd' d='M-66 -10V3.29202L141.486 43.1681V-10H-66Z' fill='url(#paint0_linear)'/><path fill-rule='evenodd' clip-rule='evenodd' d='M-66 -10V123.575L121.133 -10H-66Z' fill='url(#paint1_linear)'/><path fill-rule='evenodd' clip-rule='evenodd' d='M-66 -10V70.4069L141.486 -10H-66Z' fill='url(#paint2_linear)'/><path fill-rule='evenodd' clip-rule='evenodd' d='M-66 3.3575H141.486V-10H-66V3.3575Z' fill='url(#paint3_linear)'/></g><defs><linearGradient id='paint0_linear' x1='-66' y1='-10' x2='-66' y2='43.1681' gradientUnits='userSpaceOnUse'><stop stop-color='#01B6D1' stop-opacity='0.6'/><stop offset='1' stop-color='#01B6D1' stop-opacity='0.25'/></linearGradient><linearGradient id='paint1_linear' x1='-66' y1='-10' x2='-66' y2='123.575' gradientUnits='userSpaceOnUse'><stop stop-color='#01B6D1' stop-opacity='0.35'/><stop offset='1' stop-color='#01B6D1' stop-opacity='0.15'/></linearGradient><linearGradient id='paint2_linear' x1='-66' y1='-10' x2='-66' y2='70.4069' gradientUnits='userSpaceOnUse'><stop stop-color='#01B6D1' stop-opacity='0.6'/><stop offset='1' stop-color='#01B6D1' stop-opacity='0.25'/></linearGradient><linearGradient id='paint3_linear' x1='-66' y1='3.3575' x2='141.486' y2='3.3575' gradientUnits='userSpaceOnUse'><stop stop-color='#01B6D1' stop-opacity='0.6'/><stop offset='1' stop-color='#01B6D1' stop-opacity='0.35'/></linearGradient></defs></svg>") !default;
$monogram-warning:          url("data:image/svg+xml,<svg width='96' height='96' viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='48' cy='48' r='48' fill='#974A07'/><mask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='96' height='96'><circle cx='48' cy='48' r='48' fill='#006184'/></mask><g mask='url(#mask0)'><path fill-rule='evenodd' clip-rule='evenodd' d='M-66 -10V3.29202L141.486 43.1681V-10H-66Z' fill='url(#paint0_linear)'/><path fill-rule='evenodd' clip-rule='evenodd' d='M-66 -10V123.575L121.133 -10H-66Z' fill='url(#paint1_linear)'/><path fill-rule='evenodd' clip-rule='evenodd' d='M-66 -10V70.4069L141.486 -10H-66Z' fill='url(#paint2_linear)'/><path fill-rule='evenodd' clip-rule='evenodd' d='M-66 3.3575H141.486V-10H-66V3.3575Z' fill='url(#paint3_linear)'/></g><defs><linearGradient id='paint0_linear' x1='-66' y1='-10' x2='-66' y2='43.1681' gradientUnits='userSpaceOnUse'><stop stop-color='#F68D2E' stop-opacity='0.6'/><stop offset='1' stop-color='#F68D2E' stop-opacity='0.25'/></linearGradient><linearGradient id='paint1_linear' x1='-66' y1='-10' x2='-66' y2='123.575' gradientUnits='userSpaceOnUse'><stop stop-color='#F68D2E' stop-opacity='0.35'/><stop offset='1' stop-color='#F68D2E' stop-opacity='0.15'/></linearGradient><linearGradient id='paint2_linear' x1='-66' y1='-10' x2='-66' y2='70.4069' gradientUnits='userSpaceOnUse'><stop stop-color='#F68D2E' stop-opacity='0.6'/><stop offset='1' stop-color='#F68D2E' stop-opacity='0.25'/></linearGradient><linearGradient id='paint3_linear' x1='-66' y1='3.3575' x2='141.486' y2='3.3575' gradientUnits='userSpaceOnUse'><stop stop-color='#F68D2E' stop-opacity='0.6'/><stop offset='1' stop-color='#F68D2E' stop-opacity='0.35'/></linearGradient></defs></svg>") !default;
$monogram-danger:           url("data:image/svg+xml,<svg width='96' height='96' viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='48' cy='48' r='48' fill='#8F124A'/><mask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='96' height='96'><circle cx='48' cy='48' r='48' fill='#006184'/></mask><g mask='url(#mask0)'><path fill-rule='evenodd' clip-rule='evenodd' d='M-66 -10V3.29202L141.486 43.1681V-10H-66Z' fill='url(#paint0_linear)'/><path fill-rule='evenodd' clip-rule='evenodd' d='M-66 -10V123.575L121.133 -10H-66Z' fill='url(#paint1_linear)'/><path fill-rule='evenodd' clip-rule='evenodd' d='M-66 -10V70.4069L141.486 -10H-66Z' fill='url(#paint2_linear)'/><path fill-rule='evenodd' clip-rule='evenodd' d='M-66 3.3575H141.486V-10H-66V3.3575Z' fill='url(#paint3_linear)'/></g><defs><linearGradient id='paint0_linear' x1='-66' y1='-10' x2='-66' y2='43.1681' gradientUnits='userSpaceOnUse'><stop stop-color='#FC4E6D' stop-opacity='0.6'/><stop offset='1' stop-color='#FC4E6D' stop-opacity='0.25'/></linearGradient><linearGradient id='paint1_linear' x1='-66' y1='-10' x2='-66' y2='123.575' gradientUnits='userSpaceOnUse'><stop stop-color='#FC4E6D' stop-opacity='0.35'/><stop offset='1' stop-color='#FC4E6D' stop-opacity='0.15'/></linearGradient><linearGradient id='paint2_linear' x1='-66' y1='-10' x2='-66' y2='70.4069' gradientUnits='userSpaceOnUse'><stop stop-color='#FC4E6D' stop-opacity='0.6'/><stop offset='1' stop-color='#FC4E6D' stop-opacity='0.25'/></linearGradient><linearGradient id='paint3_linear' x1='-66' y1='3.3575' x2='141.486' y2='3.3575' gradientUnits='userSpaceOnUse'><stop stop-color='#FC4E6D' stop-opacity='0.6'/><stop offset='1' stop-color='#FC4E6D' stop-opacity='0.35'/></linearGradient></defs></svg>") !default;
$monogram-success:          url("data:image/svg+xml,<svg width='96' height='96' viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='48' cy='48' r='48' fill='#206B3F'/><mask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='96' height='96'><circle cx='48' cy='48' r='48' fill='#006184'/></mask><g mask='url(#mask0)'><path fill-rule='evenodd' clip-rule='evenodd' d='M-66 -10V3.29202L141.486 43.1681V-10H-66Z' fill='url(#paint0_linear)'/><path fill-rule='evenodd' clip-rule='evenodd' d='M-66 -10V123.575L121.133 -10H-66Z' fill='url(#paint1_linear)'/><path fill-rule='evenodd' clip-rule='evenodd' d='M-66 -10V70.4069L141.486 -10H-66Z' fill='url(#paint2_linear)'/><path fill-rule='evenodd' clip-rule='evenodd' d='M-66 3.3575H141.486V-10H-66V3.3575Z' fill='url(#paint3_linear)'/></g><defs><linearGradient id='paint0_linear' x1='-66' y1='-10' x2='-66' y2='43.1681' gradientUnits='userSpaceOnUse'><stop stop-color='#6DB344' stop-opacity='0.6'/><stop offset='1' stop-color='#6DB344' stop-opacity='0.25'/></linearGradient><linearGradient id='paint1_linear' x1='-66' y1='-10' x2='-66' y2='123.575' gradientUnits='userSpaceOnUse'><stop stop-color='#6DB344' stop-opacity='0.35'/><stop offset='1' stop-color='#6DB344' stop-opacity='0.15'/></linearGradient><linearGradient id='paint2_linear' x1='-66' y1='-10' x2='-66' y2='70.4069' gradientUnits='userSpaceOnUse'><stop stop-color='#6DB344' stop-opacity='0.6'/><stop offset='1' stop-color='#6DB344' stop-opacity='0.25'/></linearGradient><linearGradient id='paint3_linear' x1='-66' y1='3.3575' x2='141.486' y2='3.3575' gradientUnits='userSpaceOnUse'><stop stop-color='#6DB344' stop-opacity='0.6'/><stop offset='1' stop-color='#6DB344' stop-opacity='0.35'/></linearGradient></defs></svg>") !default;




//Icons
$icon-padding:                0 .7rem !default;
$icon-bg-repeat:              no-repeat !default;
$icon-bg-position:            center center !default;
$icon-margin:                 0 4px 0 0 !default;

$icon-chevron-down:          url("data:image/svg+xml,<svg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.46232 6.67331C5.53222 6.74529 5.61585 6.8025 5.70826 6.84157C5.80067 6.88064 5.89998 6.90077 6.00032 6.90077C6.10065 6.90077 6.19996 6.88064 6.29237 6.84157C6.38478 6.8025 6.46841 6.74529 6.53832 6.67331L11.7883 1.27332C11.857 1.20266 11.9111 1.11918 11.9475 1.02762C11.9839 0.936064 12.0019 0.838232 12.0006 0.739709C11.9992 0.641186 11.9784 0.543902 11.9394 0.453412C11.9004 0.362921 11.844 0.280997 11.7733 0.212315C11.7027 0.143634 11.6192 0.0895404 11.5276 0.0531242C11.4361 0.0167079 11.3382 -0.00131781 11.2397 7.507e-05C11.1412 0.00146795 11.0439 0.0222528 10.9534 0.0612428C10.8629 0.100233 10.781 0.156664 10.7123 0.227316L6.00232 5.07731L1.28832 0.227316C1.14961 0.0846292 0.959898 0.00288812 0.760922 7.507e-05C0.561946 -0.00273798 0.370002 0.0736071 0.227316 0.212315C0.0846292 0.351023 0.00288812 0.540733 7.507e-05 0.739709C-0.00273798 0.938685 0.0736071 1.13063 0.212315 1.27332L5.46232 6.67331Z' fill='" + $gray-1100 + "'/></svg>") !default;
$icon-chevron-up:            url("data:image/svg+xml,<svg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M6.53844 0.227452C6.46853 0.155482 6.3849 0.0982684 6.29249 0.0591993C6.20008 0.0201303 6.10077 0 6.00044 0C5.90011 0 5.8008 0.0201303 5.70838 0.0591993C5.61597 0.0982684 5.53234 0.155482 5.46244 0.227452L0.212437 5.62745C0.0737291 5.77014 -0.00261591 5.96208 0.00019714 6.16106C0.00301019 6.36003 0.0847512 6.54974 0.227438 6.68845C0.370124 6.82716 0.562068 6.90351 0.761044 6.90069C0.96002 6.89788 1.14973 6.81614 1.28844 6.67345L5.99044 1.81545L10.7124 6.67345C10.8511 6.81614 11.0409 6.89788 11.2398 6.90069C11.4388 6.90351 11.6308 6.82716 11.7734 6.68845C11.9161 6.54974 11.9979 6.36003 12.0007 6.16106C12.0035 5.96208 11.9271 5.77014 11.7884 5.62745L6.53844 0.227452Z' fill='" + $gray-1100 + "'/></svg>") !default;
$icon-info:                  url("data:image/svg+xml,<svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M10.5 20C4.977 20 0.5 15.523 0.5 10C0.5 4.477 4.977 0 10.5 0C16.023 0 20.5 4.477 20.5 10C20.5 15.523 16.023 20 10.5 20ZM10.6 3.45C11.3522 3.45 11.936 3.9973 11.936 4.732C11.936 5.46025 11.3506 6.005 10.6 6.005C9.84948 6.005 9.26404 5.46025 9.26404 4.732C9.26404 3.9973 9.84784 3.45 10.6 3.45ZM9.51404 7.5V16.3837H11.686V7.5H9.51404Z' fill='" + $churchInfo10 + "'/></svg>") !default;
$icon-warning:               url("data:image/svg+xml,<svg width='21' height='18' viewBox='0 0 21 18' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M9.60366 0.717456C10.002 0.0274498 10.998 0.0274493 11.3963 0.717456L20.3598 16.2426C20.7582 16.9326 20.2602 17.7951 19.4634 17.7951H1.53655C0.739802 17.7951 0.241834 16.9326 0.640209 16.2426L9.60366 0.717456ZM11.6785 5.86516H9.32148L9.54349 11.55H11.4577L11.6785 5.86516ZM11.76 14.05C11.76 14.7403 11.2004 15.3 10.51 15.3C9.81964 15.3 9.26 14.7403 9.26 14.05C9.26 13.3596 9.81964 12.8 10.51 12.8C11.2004 12.8 11.76 13.3596 11.76 14.05Z' fill='" + $churchWarning40 + "'/></svg>") !default;
$icon-danger:                url("data:image/svg+xml,<svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M10.5 20C4.977 20 0.5 15.523 0.5 10C0.5 4.477 4.977 0 10.5 0C16.023 0 20.5 4.477 20.5 10C20.5 15.523 16.023 20 10.5 20ZM9.328 4.527L9.516 12.089H11.484L11.672 4.527H9.328ZM11.773 14.769C11.773 14.081 11.211 13.542 10.5 13.542C9.79 13.542 9.227 14.082 9.227 14.769C9.227 15.456 9.789 15.995 10.5 15.995C11.21 15.995 11.773 15.456 11.773 14.769Z' fill='" + $churchDanger40 + "'/></svg>") !default;
$icon-success:               url("data:image/svg+xml,<svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M10.5 20C16.0228 20 20.5 15.5228 20.5 10C20.5 4.47715 16.0228 0 10.5 0C4.97715 0 0.5 4.47715 0.5 10C0.5 15.5228 4.97715 20 10.5 20ZM15.6925 5.75012C15.5703 5.79855 15.4588 5.87064 15.3646 5.96226L7.91276 13.1578L5.72596 10.4568C5.55969 10.2489 5.31716 10.1148 5.05261 10.0851C4.78775 10.0554 4.52194 10.1321 4.31364 10.2984L4.31165 10.3C4.20915 10.3835 4.12418 10.4865 4.06163 10.603C3.99909 10.7195 3.96019 10.8472 3.94719 10.9788C3.9342 11.1104 3.94735 11.2433 3.98589 11.3698C4.02431 11.4959 4.08721 11.6131 4.17098 11.7149L4.17179 11.7159L6.99031 15.1961C7.07291 15.2981 7.17443 15.3831 7.28926 15.4464C7.67451 15.7049 8.1946 15.6654 8.53926 15.3326L16.7543 7.4006L16.7555 7.39936C16.9443 7.2145 17.0528 6.96279 17.0575 6.69862C17.0622 6.43444 16.9628 6.17901 16.7808 5.9875L16.7799 5.98656C16.6887 5.89166 16.5797 5.81573 16.4591 5.76313C16.3385 5.71053 16.2086 5.6823 16.0771 5.68006C15.9455 5.67783 15.8148 5.70164 15.6925 5.75012Z' fill='" + $churchConfirm40 + "'/></svg>") !default;
$icon-locked:                url("data:image/svg+xml,<svg width='21' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill='#{$churchDanger40}' d='M20.36 10.12V4.11c0-2.24-1.69-4.06-3.77-4.06H7.41c-2.08 0-3.77 1.82-3.77 4.06v6.01c-1.27 0.57-2.16 1.92-2.16 3.48v6.56c0 2.08 1.58 3.77 3.51 3.77h14.04c1.93 0 3.51-1.7 3.51-3.77V13.6C22.53 12.04 21.63 10.69 20.36 10.12z M12.98 16.82v3.29c0 0.58-0.44 1.06-0.98 1.06s-0.98-0.48-0.98-1.06v-3.29c-0.47-0.34-0.78-0.92-0.78-1.58c0-1.05 0.79-1.9 1.77-1.9s1.77 0.85 1.77 1.9C13.77 15.9 13.46 16.48 12.98 16.82z M18.5 9.82H5.5V4.11c0-1.13 0.86-2.06 1.91-2.06h9.18c1.05 0 1.91 0.92 1.91 2.06V9.82z'/%3E%3C/svg></svg>") !default;

// Body
//
// Settings for the `<body>` element.

$body-bg:                   $white !default;
$body-color:                $churchText120 !default;
$body-text-align:           null !default;

// Links
//
// Style anchor elements.

$link-color:                              $primary !default;
$link-decoration:                         none !default;
$link-shade-percentage:                   20% !default;
$link-hover-color:                        shift-color($link-color, $link-shade-percentage) !default;
$link-hover-decoration:                   null !default;

$stretched-link-pseudo-element:           after !default;
$stretched-link-z-index:                  1 !default;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   1rem !default;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// scss-docs-start grid-breakpoints
/*$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;*/
$grid-breakpoints: (
  xxs: 0,
  xs: 480px,
  sm: 600px,
  md: 840px,
  lg: 960px,
  xl: 1280px,
  xxl: 1440px,
  xxxl: 1600px
) !default;
// scss-docs-end grid-breakpoints

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
  xs: 480px,
  sm: 600px,
  md: 840px,
  lg: 960px,
  xl: 1280px,
  xxl: 1440px,
  xxxl: 1600px
) !default;
// scss-docs-end container-max-widths

@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           1.5rem !default;
$grid-row-columns:            6 !default;

// Container padding

$container-padding-x: $grid-gutter-width !default;


// Components
//
// Define common padding and border radius sizes and more.

// scss-docs-start border-variables
$border-width:                1px !default;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px
) !default;

$border-style:                solid !default;
$border-color:                $gray-300 !default;
$border-color-translucent:    rgba($black, .175) !default;
// scss-docs-end border-variables

// scss-docs-start border-radius-variables
$border-radius:               .125rem !default;
$border-radius-sm:            $border-radius;
$border-radius-lg:            $border-radius;
$border-radius-xl:            $border-radius;
$border-radius-2xl:           $border-radius;
$border-radius-pill:          50rem !default;
// scss-docs-end border-radius-variables

// scss-docs-start box-shadow-variables
$box-shadow:                  0 .25rem .5rem rgba($black, .2) !default;
$box-shadow-sm:               0 .125rem .25rem rgba($black, .2) !default;
$box-shadow-lg:               0 .5rem 1rem rgba($black, .2) !default;
$box-shadow-xl:               0 1rem 2rem rgba($black, .2) !default;
$box-shadow-inset:            inset 0 1px 2px rgba($black, .075) !default;
// scss-docs-end box-shadow-variables

$component-active-color:      $white !default;
$component-active-bg:         $primary !default;

// scss-docs-start caret-variables
$caret-width:                 .3em !default;
$caret-vertical-align:        $caret-width * .85 !default;
$caret-spacing:               $caret-width * .85 !default;
// scss-docs-end caret-variables

$transition-base:             all .2s ease-in-out !default;
$transition-fade:             opacity .15s linear !default;
// scss-docs-start collapse-transition
$transition-collapse:         height .35s ease !default;
$transition-collapse-width:   width .35s ease !default;
// scss-docs-end collapse-transition

// stylelint-disable function-disallowed-list
// scss-docs-start aspect-ratios
$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%)
) !default;
// scss-docs-end aspect-ratios
// stylelint-enable function-disallowed-list

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// scss-docs-start font-variables
// stylelint-disable value-keyword-case
$font-family-sans-serif:      "Zoram", Arial;
$font-family-serif:           McKay, Georgia;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
// stylelint-enable value-keyword-case
$font-family-base:            var(--#{$prefix}font-sans-serif) !default;
$font-family-code:            var(--#{$prefix}font-monospace) !default;

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root:              null !default;
$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm:                $font-size-base * .875 !default;
$font-size-lg:                $font-size-base * 1.25 !default;

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-semibold:        600 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bolder !default;

$font-weight-base:            $font-weight-normal !default;

$line-height-base:            1.4 !default;
$line-height-sm:              1.2 !default;
$line-height-lg:              1.6 !default;

$h1-font-size:                $font-size-base * 2.625 !default;
$h2-font-size:                $font-size-base * 2 !default;
$h3-font-size:                $font-size-base * 1.25 !default;
$h4-font-size:                $font-size-base * 1.125 !default;
$h5-font-size:                $font-size-base * 0.875 !default;
$h6-font-size:                $font-size-base * 0.8125 !default;

$h1-line-height:              2.4rem !default;
$h2-line-height:              2.4rem !default;
$h3-line-height:              1.5rem !default;
$h4-line-height:              1.35rem !default;
$h5-line-height:              1.05rem !default;
$h6-line-height:              .975rem !default;
// scss-docs-end font-variables

// scss-docs-start font-sizes
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size
) !default;
// scss-docs-end font-sizes

// scss-docs-start headings-variables
$headings-margin-bottom:      $spacer * .5 !default;
$headings-font-family:        null !default;
$headings-font-style:         null !default;
$headings-font-weight:        700 !default;
$headings-line-height:        1.2 !default;
$headings-color:              null !default;
// scss-docs-end headings-variables

// scss-docs-start display-headings
$display-font-sizes: (
  1: 2.625rem,
  2: 2rem,
  3: 1.25rem,
  4: 1.125rem,
  5: 0.875rem,
  6: 0.8125rem
) !default;

$display-font-family: null !default;
$display-font-style:  null !default;
$display-font-weight: 300 !default;
$display-line-height: $headings-line-height !default;
// scss-docs-end display-headings

// scss-docs-start type-variables
$lead-font-size:              $font-size-base * 1.125 !default;
$lead-font-weight:            300 !default;

$small-font-size:             .8125em !default;

$sub-sup-font-size:           .6875em !default;

$text-muted:                  $gray-1000 !default;

$initialism-font-size:        $small-font-size !default;

$blockquote-margin-y:         $spacer !default;
$blockquote-font-size:        $font-size-base * 1.25 !default;
$blockquote-footer-color:     $gray-1000 !default;
$blockquote-footer-font-size: $small-font-size !default;

$hr-margin-y:                 $spacer !default;
$hr-color:                    $gray-500 !default;

// fusv-disable
$hr-bg-color:                 null !default; // Deprecated in v5.2.0
$hr-height:                   null !default; // Deprecated in v5.2.0
// fusv-enable

$hr-border-color:             null !default; // Allows for inherited colors
$hr-border-width:             $border-width !default;
$hr-opacity:                  .25 !default;

$legend-margin-bottom:        .5rem !default;
$legend-font-size:            1.5rem !default;
$legend-font-weight:          null !default;

$dt-font-weight:              $font-weight-bold !default;

$list-inline-padding:         .5rem !default;

$mark-padding:                .1875em !default;
$mark-bg:                     $yellow !default;
// scss-docs-end type-variables


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

// scss-docs-start table-variables
$table-cell-padding-y:        .5rem !default;
$table-cell-padding-x:        .5rem !default;
$table-cell-padding-y-sm:     .25rem !default;
$table-cell-padding-x-sm:     .25rem !default;

$table-cell-vertical-align:   top !default;

$table-color:                 var(--#{$prefix}body-color) !default;
$table-bg:                    transparent !default;
$table-accent-bg:             transparent !default;

$table-th-font-weight:        null !default;

$table-striped-color:         $table-color !default;
$table-striped-bg-factor:     .05 !default;
$table-striped-bg:            $gray-200 !default;

$table-active-color:          $table-color !default;
$table-active-bg-factor:      .1 !default;
$table-active-bg:             rgba($black, $table-active-bg-factor) !default;

$table-hover-color:           $table-color !default;
$table-hover-bg-factor:       .075 !default;
$table-hover-bg:              rgba($black, $table-hover-bg-factor) !default;

$table-border-factor:         .1 !default;
$table-border-width:          $border-width !default;
$table-border-color:          var(--#{$prefix}border-color) !default;

$table-striped-order:         odd !default;
$table-striped-columns-order: even !default;

$table-group-separator-color: currentcolor !default;

$table-caption-color:         $text-muted !default;

$table-bg-scale:              -80% !default;
// scss-docs-end table-variables

// scss-docs-start table-loop
$table-variants: (
  "primary":    shift-color($primary, $table-bg-scale),
  "secondary":  shift-color($secondary, $table-bg-scale),
  "success":    shift-color($success, $table-bg-scale),
  "warning":    shift-color($warning, $table-bg-scale),
  "danger":     shift-color($danger, $table-bg-scale),
  "light":      $light,
  "dark":       $dark,
) !default;
// scss-docs-end table-loop


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// scss-docs-start input-btn-variables
$input-btn-padding-y:         .875rem !default;
$input-btn-padding-x:         1rem !default;
$input-btn-font-family:       null !default;
$input-btn-font-size:         $font-size-base !default;
$input-btn-line-height:       $line-height-sm !default;

$input-btn-focus-width:         .25rem !default;
$input-btn-focus-color-opacity: .25 !default;
$input-btn-focus-color:         rgba($component-active-bg, $input-btn-focus-color-opacity) !default;
$input-btn-focus-blur:          0 !default;
$input-btn-focus-box-shadow:    0 0 $input-btn-focus-blur $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y-sm:      .25rem !default;
$input-btn-padding-x-sm:      .5rem !default;
$input-btn-font-size-sm:      $font-size-sm !default;

$input-btn-padding-y-lg:      .5rem !default;
$input-btn-padding-x-lg:      1rem !default;
$input-btn-font-size-lg:      $font-size-lg !default;

$input-btn-border-width:      $border-width !default;
// scss-docs-end input-btn-variables


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

// scss-docs-start btn-variables
/*Church Buttons*/
$churchuttonHoverBorder:        #2360C5;

$churchPrimaryButtonDefault:    #006184 !default;
$churchPrimaryButtonHover:      #007DA5 !default;
$churchPrimaryButtonFocus:      #006184 !default;
$churchPrimaryButtonPress:      #003057 !default;

$churchDangerButtonDefault:     #B00504 !default;
$churchDangerButtonHover:       #DC3838 !default;
$churchDangerButtonFocus:       #B00504 !default;
$churchDangerButtonPress:       #960101 !default;

$churchGhostButtonDefault:      $white;
$churchGhostButtonColor:        #006184;
$churchGhostButtonHover:        $churchGray03;
$churchGhostButtonFocus:        $white;
$churchGhostButtonPress:        $churchGray05;

// scss-docs-start theme-ui-colors-map
$theme-button-colors: (
  "primary":    $churchPrimaryButtonDefault,
  "danger":     $churchDangerButtonDefault,
  "ghost":      $churchGhostButtonDefault
) !default;
// scss-docs-end theme-ui-colors-map

$btn-padding-y:               0.4375rem !default;
$btn-padding-x:               1rem !default;
$btn-font-family:             $input-btn-font-family !default;
$btn-font-size:               $input-btn-font-size !default;
$btn-line-height:             $input-btn-line-height !default;
$btn-white-space:             null !default; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm:            0.375rem !default;
$btn-padding-x-sm:            $btn-padding-x !default;
$btn-font-size-sm:            $input-btn-font-size-sm !default;

$btn-padding-y-lg:            $input-btn-padding-y-lg !default;
$btn-padding-x-lg:            $input-btn-padding-x-lg !default;
$btn-font-size-lg:            $input-btn-font-size-lg !default;

$btn-border-width:            2px !default;

$btn-font-weight:             $font-weight-normal !default;
$btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;
$btn-focus-width:             $input-btn-focus-width !default;
$btn-focus-box-shadow:        $input-btn-focus-box-shadow !default;
$btn-disabled-opacity:        .65 !default;
$btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125) !default;

$btn-link-color:              var(--#{$prefix}link-color) !default;
$btn-link-hover-color:        var(--#{$prefix}link-hover-color) !default;
$btn-link-disabled-color:     $gray-1000 !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           $border-radius !default;
$btn-border-radius-sm:        $border-radius-sm !default;
$btn-border-radius-lg:        $border-radius-lg !default;

$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$btn-hover-bg-shade-amount:       15% !default;
$btn-hover-bg-tint-amount:        15% !default;
$btn-hover-border-shade-amount:   20% !default;
$btn-hover-border-tint-amount:    10% !default;
$btn-active-bg-shade-amount:      20% !default;
$btn-active-bg-tint-amount:       20% !default;
$btn-active-border-shade-amount:  25% !default;
$btn-active-border-tint-amount:   10% !default;
// scss-docs-end btn-variables


// Forms

// scss-docs-start form-text-variables
$form-text-margin-top:                  .25rem !default;
$form-text-font-size:                   $small-font-size !default;
$form-text-font-style:                  null !default;
$form-text-font-weight:                 null !default;
$form-text-color:                       $text-muted !default;
// scss-docs-end form-text-variables

// scss-docs-start form-label-variables
$form-label-margin-bottom:              .5rem !default;
$form-label-font-size:                  null !default;
$form-label-font-style:                 null !default;
$form-label-font-weight:                null !default;
$form-label-color:                      null !default;
// scss-docs-end form-label-variables

// scss-docs-start form-input-variables
$input-padding-y:                       .5rem !default;
$input-padding-x:                       .5rem !default;
$input-font-family:                     $input-btn-font-family !default;
$input-font-size:                       $input-btn-font-size !default;
$input-font-weight:                     $font-weight-base !default;
$input-line-height:                     $input-btn-line-height !default;

$input-padding-y-sm:                    .25rem !default;
$input-padding-x-sm:                    .25rem !default;
$input-font-size-sm:                    $input-btn-font-size-sm !default;

$input-padding-y-lg:                    $input-btn-padding-y-lg !default;
$input-padding-x-lg:                    $input-btn-padding-x-lg !default;
$input-font-size-lg:                    $input-btn-font-size-lg !default;

$input-bg:                              $body-bg !default;
$input-disabled-color:                  null !default;
$input-disabled-bg:                     $gray-200 !default;
$input-disabled-border-color:           null !default;

$input-color:                           $body-color !default;
$input-border-color:                    $gray-800 !default;
$input-border-width:                    $input-btn-border-width !default;
$input-box-shadow:                      null !default;

$input-border-radius:                   $border-radius !default;
$input-border-radius-sm:                $border-radius-sm !default;
$input-border-radius-lg:                $border-radius-lg !default;

$input-focus-bg:                        $input-bg !default;
$input-focus-border-color:              $primary !default;
$input-focus-color:                     $input-color !default;
$input-focus-width:                     $input-btn-focus-width !default;
$input-focus-box-shadow:                null !default;

$input-placeholder-color:               $gray-1000 !default;
$input-plaintext-color:                 $body-color !default;

$input-height-border:                   $input-border-width * 2 !default;

$input-height-inner:                    add($input-line-height * 1em, $input-padding-y * 2) !default;
$input-height-inner-half:               add($input-line-height * .5em, $input-padding-y) !default;
$input-height-inner-quarter:            add($input-line-height * .25em, $input-padding-y * .5) !default;

$input-height:                          add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false)) !default;
$input-height-sm:                       add($input-line-height * 1em, add($input-padding-y-sm * 2, $input-height-border, false)) !default;
$input-height-lg:                       add($input-line-height * 1em, add($input-padding-y-lg * 2, $input-height-border, false)) !default;

$input-transition:                      border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$form-color-width:                      3rem !default;
// scss-docs-end form-input-variables

// scss-docs-start form-check-variables
$form-check-input-width:                  1em !default;
$form-check-min-height:                   $font-size-base * $line-height-base !default;
$form-check-padding-start:                $form-check-input-width + .5em !default;
$form-check-margin-bottom:                .125rem !default;
$form-check-label-color:                  null !default;
$form-check-label-cursor:                 null !default;
$form-check-transition:                   null !default;

$form-check-input-active-filter:          brightness(90%) !default;

$form-check-input-bg:                     $input-bg !default;
$form-check-input-border:                 1px solid rgba($black, .25) !default;
$form-check-input-border-radius:          .25em !default;
$form-check-radio-border-radius:          50% !default;
$form-check-input-focus-border:           $input-focus-border-color !default;
$form-check-input-focus-box-shadow:       null !default;

$form-check-input-checked-color:          $component-active-color !default;
$form-check-input-checked-bg-color:       $component-active-bg !default;
$form-check-input-checked-border-color:   $form-check-input-checked-bg-color !default;
$form-check-input-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>") !default;
$form-check-radio-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>") !default;

$form-check-input-indeterminate-color:          $component-active-color !default;
$form-check-input-indeterminate-bg-color:       $component-active-bg !default;
$form-check-input-indeterminate-border-color:   $form-check-input-indeterminate-bg-color !default;
$form-check-input-indeterminate-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>") !default;

$form-check-input-disabled-opacity:        .5 !default;
$form-check-label-disabled-opacity:        $form-check-input-disabled-opacity !default;
$form-check-btn-check-disabled-opacity:    $btn-disabled-opacity !default;

$form-check-inline-margin-end:    1rem !default;
// scss-docs-end form-check-variables

// scss-docs-start form-switch-variables
$form-switch-color:               $white !default;
$form-switch-width:               2.625em !default;
$form-switch-height:              1.5em !default;
$form-switch-padding-start:       $form-switch-width + .5em !default;
$form-switch-bg-image:            url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3.8' fill='#{$form-switch-color}'/></svg>") !default;
$form-switch-border-radius:       $form-switch-width !default;
$form-switch-transition:          background-position .10s ease-in-out !default;

$form-switch-focus-color:         $white !default;
$form-switch-focus-bg-image:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3.8' fill='#{$form-switch-focus-color}'/></svg>") !default;

$form-switch-checked-color:       $component-active-color !default;
$form-switch-checked-bg-image:    url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3.8' fill='#{$form-switch-checked-color}'/></svg>") !default;
$form-switch-checked-bg-position: right center !default;
// scss-docs-end form-switch-variables

// scss-docs-start input-group-variables
$input-group-addon-padding-y:           $input-padding-y !default;
$input-group-addon-padding-x:           $input-padding-x !default;
$input-group-addon-font-weight:         $input-font-weight !default;
$input-group-addon-color:               $input-color !default;
$input-group-addon-bg:                  $gray-200 !default;
$input-group-addon-border-color:        $input-border-color !default;
// scss-docs-end input-group-variables

// scss-docs-start form-select-variables
$form-select-padding-y:             $input-padding-y !default;
$form-select-padding-x:             $input-padding-x !default;
$form-select-font-family:           $input-font-family !default;
$form-select-font-size:             $input-font-size !default;
$form-select-indicator-padding:     $form-select-padding-x * 3 !default; // Extra padding for background-image
$form-select-font-weight:           $input-font-weight !default;
$form-select-line-height:           $input-line-height !default;
$form-select-color:                 $input-color !default;
$form-select-bg:                    $input-bg !default;
$form-select-disabled-color:        null !default;
$form-select-disabled-bg:           $gray-200 !default;
$form-select-disabled-border-color: $input-disabled-border-color !default;
$form-select-bg-position:           right $form-select-padding-x center !default;
$form-select-bg-size:               16px 12px !default; // In pixels because image dimensions
$form-select-indicator-color:       $gray-800 !default;
$form-select-indicator:             url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg>") !default;

$form-select-feedback-icon-padding-end: $form-select-padding-x * 2.5 + $form-select-indicator-padding !default;
$form-select-feedback-icon-position:    center right $form-select-indicator-padding !default;
$form-select-feedback-icon-size:        $input-height-inner-half $input-height-inner-half !default;

$form-select-border-width:        $input-border-width !default;
$form-select-border-color:        $input-border-color !default;
$form-select-border-radius:       $input-border-radius !default;
$form-select-box-shadow:          $box-shadow-inset !default;

$form-select-focus-border-color:  $input-focus-border-color !default;
$form-select-focus-width:         $input-focus-width !default;
$form-select-focus-box-shadow:    null !default;

$form-select-padding-y-sm:        $input-padding-y-sm !default;
$form-select-padding-x-sm:        $input-padding-x-sm !default;
$form-select-font-size-sm:        $input-font-size-sm !default;
$form-select-border-radius-sm:    $input-border-radius-sm !default;

$form-select-padding-y-lg:        $input-padding-y-lg !default;
$form-select-padding-x-lg:        $input-padding-x-lg !default;
$form-select-font-size-lg:        $input-font-size-lg !default;
$form-select-border-radius-lg:    $input-border-radius-lg !default;

$form-select-transition:          $input-transition !default;
// scss-docs-end form-select-variables

// scss-docs-start form-range-variables
$form-range-track-width:          100% !default;
$form-range-track-height:         .25rem !default;
$form-range-track-cursor:         pointer !default;
$form-range-track-bg:             $component-active-bg !default;
$form-range-track-border-radius:  .0625rem !default;
$form-range-track-box-shadow:     null !default;

$form-range-thumb-width:                   1rem !default;
$form-range-thumb-height:                  $form-range-thumb-width !default;
$form-range-thumb-bg:                      $component-active-bg !default;
$form-range-thumb-border:                  0 !default;
$form-range-thumb-border-radius:           1.25rem !default;
$form-range-thumb-box-shadow:              null !default;
$form-range-thumb-focus-box-shadow:        0 0 0 1px $body-bg, $input-focus-box-shadow !default;
$form-range-thumb-focus-box-shadow-width:  $input-focus-width !default; // For focus box shadow issue in Edge
$form-range-thumb-active-bg:               tint-color($component-active-bg, 70%) !default;
$form-range-thumb-disabled-bg:             $gray-500 !default;
$form-range-thumb-transition:              background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;
// scss-docs-end form-range-variables

// scss-docs-start form-file-variables
$form-file-button-color:          $input-color !default;
$form-file-button-bg:             $input-group-addon-bg !default;
$form-file-button-hover-bg:       shade-color($form-file-button-bg, 5%) !default;
// scss-docs-end form-file-variables

// scss-docs-start form-floating-variables
$form-floating-height:            add(3.5rem, $input-height-border) !default;
$form-floating-line-height:       1.25 !default;
$form-floating-padding-x:         $input-padding-x !default;
$form-floating-padding-y:         1rem !default;
$form-floating-input-padding-t:   1.625rem !default;
$form-floating-input-padding-b:   .625rem !default;
$form-floating-label-opacity:     .65 !default;
$form-floating-label-transform:   scale(.85) translateY(-.5rem) translateX(.15rem) !default;
$form-floating-transition:        opacity .1s ease-in-out, transform .1s ease-in-out !default;
// scss-docs-end form-floating-variables

// Form validation

// scss-docs-start form-feedback-variables
$form-feedback-margin-top:          $form-text-margin-top !default;
$form-feedback-font-size:           $form-text-font-size !default;
$form-feedback-font-style:          $form-text-font-style !default;

// scss-docs-end form-feedback-variables

// scss-docs-start form-validation-states
$form-validation-states: (
  "valid": (
    "color": $success,
    "icon": $icon-success
  ),
  "invalid": (
    "color": $danger,
    "icon": $icon-danger
  ),
  "warning": (
    "color": $input-border-color,
    "icon": $icon-warning
  ),
  "locked": (
    "color": $danger,
    "icon": $icon-locked
  )
) !default;
// scss-docs-end form-validation-states

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

// scss-docs-start zindex-stack
$zindex-dropdown:                   1000 !default;
$zindex-sticky:                     1020 !default;
$zindex-fixed:                      1030 !default;
$zindex-offcanvas-backdrop:         1040 !default;
$zindex-offcanvas:                  1045 !default;
$zindex-modal-backdrop:             1050 !default;
$zindex-modal:                      1055 !default;
$zindex-popover:                    1070 !default;
$zindex-tooltip:                    1080 !default;
$zindex-toast:                      1090 !default;
// scss-docs-end zindex-stack


// Navs

// scss-docs-start nav-variables
$nav-link-padding-y:                .5rem !default;
$nav-link-padding-x:                1rem !default;
$nav-link-font-size:                null !default;
$nav-link-font-weight:              null !default;
$nav-link-color:                    $body-color !default;
$nav-link-hover-color:              $body-color !default;
$nav-link-transition:               color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out !default;
$nav-link-disabled-color:           $gray-1000 !default;

$nav-tabs-border-color:             $gray-800 !default;
$nav-tabs-border-width:             $border-width !default;
$nav-tabs-border-radius:            $border-radius !default;
$nav-tabs-link-hover-border-color:  $gray-300 $gray-300 $nav-tabs-border-color !default;
$nav-tabs-link-hover-bg-color:      $gray-100 !default;
$nav-tabs-link-active-color:        $body-color !default;
$nav-tabs-link-active-bg:           $body-bg !default;
$nav-tabs-link-active-border-top-color:     3px solid $blue !default;
$nav-tabs-link-active-border-sides-color:   1px solid $gray-800 !default;
$nav-tabs-link-active-border-bottom-color:  1px solid $nav-tabs-link-active-bg !default;

$nav-pills-border-radius:           $border-radius !default;
$nav-pills-link-active-color:       $component-active-color !default;
$nav-pills-link-active-bg:          $component-active-bg !default;
// scss-docs-end nav-variables


// Navbar

// scss-docs-start navbar-variables
$navbar-padding-y:                  $spacer * .5 !default;
$navbar-padding-x:                  null !default;

$navbar-nav-link-padding-x:         .5rem !default;

$navbar-brand-font-size:            $font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height:                   $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;
$navbar-brand-height:               $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-padding-y:            ($nav-link-height - $navbar-brand-height) * .5 !default;
$navbar-brand-margin-end:           1rem !default;

$navbar-toggler-padding-y:          .25rem !default;
$navbar-toggler-padding-x:          .75rem !default;
$navbar-toggler-font-size:          $font-size-lg !default;
$navbar-toggler-border-radius:      $btn-border-radius !default;
$navbar-toggler-focus-width:        $btn-focus-width !default;
$navbar-toggler-transition:         box-shadow .15s ease-in-out !default;

$navbar-light-color:                rgba($black, .55) !default;
$navbar-light-hover-color:          rgba($black, .7) !default;
$navbar-light-active-color:         rgba($black, .9) !default;
$navbar-light-disabled-color:       rgba($black, .3) !default;
$navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-light-toggler-border-color: rgba($black, .1) !default;
$navbar-light-brand-color:          $navbar-light-active-color !default;
$navbar-light-brand-hover-color:    $navbar-light-active-color !default;
// scss-docs-end navbar-variables

// scss-docs-start navbar-dark-variables
$navbar-dark-color:                 rgba($white, .55) !default;
$navbar-dark-hover-color:           rgba($white, .75) !default;
$navbar-dark-active-color:          $white !default;
$navbar-dark-disabled-color:        rgba($white, .25) !default;
$navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-dark-toggler-border-color:  rgba($white, .1) !default;
$navbar-dark-brand-color:           $navbar-dark-active-color !default;
$navbar-dark-brand-hover-color:     $navbar-dark-active-color !default;
// scss-docs-end navbar-dark-variables


// Dropdowns
//
// Dropdown menu container and contents.

// scss-docs-start dropdown-variables
$dropdown-min-width:                10rem !default;
$dropdown-padding-x:                0 !default;
$dropdown-padding-y:                .5rem !default;
$dropdown-spacer:                   .125rem !default;
$dropdown-font-size:                $font-size-base !default;
$dropdown-color:                    $body-color !default;
$dropdown-bg:                       $white !default;
$dropdown-border-color:             var(--#{$prefix}border-color-translucent) !default;
$dropdown-border-radius:            $border-radius !default;
$dropdown-border-width:             $border-width !default;
$dropdown-inner-border-radius:      subtract($dropdown-border-radius, $dropdown-border-width) !default;
$dropdown-divider-bg:               $dropdown-border-color !default;
$dropdown-divider-margin-y:         $spacer * .5 !default;
$dropdown-box-shadow:               $box-shadow !default;

$dropdown-link-color:               $gray-900 !default;
$dropdown-link-hover-color:         shade-color($dropdown-link-color, 10%) !default;
$dropdown-link-hover-bg:            $gray-200 !default;

$dropdown-link-active-color:        $component-active-color !default;
$dropdown-link-active-bg:           $component-active-bg !default;

$dropdown-link-disabled-color:      $gray-500 !default;

$dropdown-item-padding-y:           $spacer * .25 !default;
$dropdown-item-padding-x:           $spacer !default;

$dropdown-header-color:             $gray-1000 !default;
$dropdown-header-padding-x:         $dropdown-item-padding-x !default;
$dropdown-header-padding-y:         $dropdown-padding-y !default;
// fusv-disable
$dropdown-header-padding:           $dropdown-header-padding-y $dropdown-header-padding-x !default; // Deprecated in v5.2.0
// fusv-enable
// scss-docs-end dropdown-variables

// scss-docs-start dropdown-dark-variables
$dropdown-dark-color:               $gray-300 !default;
$dropdown-dark-bg:                  $gray-800 !default;
$dropdown-dark-border-color:        $dropdown-border-color !default;
$dropdown-dark-divider-bg:          $dropdown-divider-bg !default;
$dropdown-dark-box-shadow:          null !default;
$dropdown-dark-link-color:          $dropdown-dark-color !default;
$dropdown-dark-link-hover-color:    $white !default;
$dropdown-dark-link-hover-bg:       rgba($white, .15) !default;
$dropdown-dark-link-active-color:   $dropdown-link-active-color !default;
$dropdown-dark-link-active-bg:      $dropdown-link-active-bg !default;
$dropdown-dark-link-disabled-color: $gray-500 !default;
$dropdown-dark-header-color:        $gray-500 !default;
// scss-docs-end dropdown-dark-variables


// Pagination

// scss-docs-start pagination-variables
$pagination-padding-y:              .375rem !default;
$pagination-padding-x:              .75rem !default;
$pagination-padding-y-sm:           0 !default;
$pagination-padding-x-sm:           .5rem !default;
$pagination-padding-y-lg:           .75rem !default;
$pagination-padding-x-lg:           1.5rem !default;

$pagination-font-size:              $font-size-base !default;

$pagination-color:                  var(--#{$prefix}link-color) !default;
$pagination-bg:                     $white !default;
$pagination-border-radius:          $border-radius !default;
$pagination-border-width:           0px !default;
$pagination-margin-start:           ($pagination-border-width * -1) !default;
$pagination-border-color:           $gray-300 !default;

$pagination-focus-color:            var(--#{$prefix}link-hover-color) !default;
$pagination-focus-bg:               $gray-200 !default;
$pagination-focus-box-shadow:       $input-btn-focus-box-shadow !default;
$pagination-focus-outline:          0 !default;

$pagination-hover-color:            var(--#{$prefix}link-hover-color) !default;
$pagination-hover-bg:               $white !default;
$pagination-hover-border-color:     $white !default;

$pagination-active-color:           $body-color !default;
$pagination-active-bg:              $white !default;
$pagination-active-border-color:    $white !default;

$pagination-disabled-color:         $gray-1000 !default;
$pagination-disabled-bg:            $white !default;
$pagination-disabled-border-color:  $gray-300 !default;

$pagination-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$pagination-border-radius-sm:       $border-radius-sm !default;
$pagination-border-radius-lg:       $border-radius-lg !default;
// scss-docs-end pagination-variables


// Placeholders

// scss-docs-start placeholders
$placeholder-opacity-max:           .5 !default;
$placeholder-opacity-min:           .2 !default;
// scss-docs-end placeholders

// Cards

// scss-docs-start card-variables
$card-spacer-y:                     $spacer !default;
$card-spacer-x:                     $spacer !default;
$card-title-spacer-y:               $spacer * .5 !default;
$card-border-width:                 $border-width !default;
$card-border-color:                 var(--#{$prefix}border-color-translucent) !default;
$card-border-radius:                $border-radius !default;
$card-box-shadow:                   $box-shadow-sm !default;
$card-inner-border-radius:          subtract($card-border-radius, $card-border-width) !default;
$card-cap-padding-y:                $card-spacer-y * .5 !default;
$card-cap-padding-x:                $card-spacer-x !default;
$card-cap-bg:                       $white !default;
$card-cap-color:                    null !default;
$card-height:                       null !default;
$card-color:                        null !default;
$card-bg:                           $white !default;
$card-img-overlay-padding:          $spacer !default;
$card-group-margin:                 $grid-gutter-width * .5 !default;
// scss-docs-end card-variables

// Accordion

// scss-docs-start accordion-variables
$accordion-padding-y:                     1rem !default;
$accordion-padding-x:                     1.25rem !default;
$accordion-color:                         $body-color !default; // Sass variable because of $accordion-button-icon
$accordion-bg:                            $body-bg !default;
$accordion-border-width:                  $border-width !default;
$accordion-border-color:                  var(--#{$prefix}border-color) !default;
$accordion-border-radius:                 $border-radius !default;
$accordion-inner-border-radius:           subtract($accordion-border-radius, $accordion-border-width) !default;

$accordion-body-padding-y:                $accordion-padding-y !default;
$accordion-body-padding-x:                $accordion-padding-x !default;

$accordion-button-padding-y:              $accordion-padding-y !default;
$accordion-button-padding-x:              $accordion-padding-x !default;
$accordion-button-color:                  $accordion-color !default;
$accordion-button-bg:                     null !default;
$accordion-transition:                    $btn-transition, border-radius .15s ease !default;
$accordion-button-active-bg:              null !default;
$accordion-button-active-color:           null !default;

$accordion-button-focus-border-color:     null !default;
$accordion-button-focus-box-shadow:       null !default;

$accordion-icon-width:                    1.25rem !default;
$accordion-icon-color:                    $accordion-button-color !default;
$accordion-icon-active-color:             $accordion-button-active-color !default;
$accordion-icon-transition:               transform .2s ease-in-out !default;
$accordion-icon-transform:                rotate(-180deg) !default;

$accordion-button-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
$accordion-button-active-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
// scss-docs-end accordion-variables

// Tooltips

// scss-docs-start tooltip-variables
$tooltip-font-size:                 $font-size-sm !default;
$tooltip-max-width:                 200px !default;
$tooltip-color:                     $white !default;
$tooltip-bg:                        $black !default;
$tooltip-border-radius:             $border-radius !default;
$tooltip-opacity:                   .9 !default;
$tooltip-padding-y:                 $spacer * .25 !default;
$tooltip-padding-x:                 $spacer * .5 !default;
$tooltip-margin:                    null !default; // TODO: remove this in v6

$tooltip-arrow-width:               .8rem !default;
$tooltip-arrow-height:              .4rem !default;
// fusv-disable
$tooltip-arrow-color:               null !default; // Deprecated in Bootstrap 5.2.0 for CSS variables
// fusv-enable
// scss-docs-end tooltip-variables

// Form tooltips must come after regular tooltips
// scss-docs-start tooltip-feedback-variables
$form-feedback-tooltip-padding-y:     $tooltip-padding-y !default;
$form-feedback-tooltip-padding-x:     $tooltip-padding-x !default;
$form-feedback-tooltip-font-size:     $tooltip-font-size !default;
$form-feedback-tooltip-line-height:   null !default;
$form-feedback-tooltip-opacity:       $tooltip-opacity !default;
$form-feedback-tooltip-border-radius: $tooltip-border-radius !default;
// scss-docs-end tooltip-feedback-variables


// Popovers

// scss-docs-start popover-variables
$popover-font-size:                 $font-size-sm !default;
$popover-bg:                        $white !default;
$popover-max-width:                 276px !default;
$popover-border-width:              $border-width !default;
$popover-border-color:              var(--#{$prefix}border-color-translucent) !default;
$popover-border-radius:             $border-radius-lg !default;
$popover-inner-border-radius:       subtract($popover-border-radius, $popover-border-width) !default;
$popover-box-shadow:                $box-shadow !default;

$popover-header-font-size:          $font-size-base !default;
$popover-header-bg:                 shade-color($popover-bg, 6%) !default;
$popover-header-color:              $headings-color !default;
$popover-header-padding-y:          .5rem !default;
$popover-header-padding-x:          $spacer !default;

$popover-body-color:                $body-color !default;
$popover-body-padding-y:            $spacer !default;
$popover-body-padding-x:            $spacer !default;

$popover-arrow-width:               1rem !default;
$popover-arrow-height:              .5rem !default;
// scss-docs-end popover-variables

// fusv-disable
// Deprecated in Bootstrap 5.2.0 for CSS variables
$popover-arrow-color:               $popover-bg !default;
$popover-arrow-outer-color:         var(--#{$prefix}border-color-translucent) !default;
// fusv-enable


// Toasts

// scss-docs-start toast-variables
$toast-max-width:                   350px !default;
$toast-padding-x:                   .75rem !default;
$toast-padding-y:                   .5rem !default;
$toast-font-size:                   .875rem !default;
$toast-color:                       null !default;
$toast-background-color:            rgba($white, .85) !default;
$toast-border-width:                $border-width !default;
$toast-border-color:                var(--#{$prefix}border-color-translucent) !default;
$toast-border-radius:               $border-radius !default;
$toast-box-shadow:                  $box-shadow !default;
$toast-spacing:                     $container-padding-x !default;

$toast-header-color:                $gray-1000 !default;
$toast-header-background-color:     rgba($white, .85) !default;
$toast-header-border-color:         rgba($black, .05) !default;
// scss-docs-end toast-variables


// Badges

// scss-docs-start badge-variables
$badge-font-size:                   .6875em !default;
$badge-font-weight:                 $font-weight-semibold !default;
$badge-color:                       $white !default;
$badge-padding-y:                   .0625em !default;
$badge-padding-x:                   .5em !default;
$badge-border-radius:               $border-radius !default;
// scss-docs-end badge-variables


// Modals

// scss-docs-start modal-variables
$modal-inner-padding:               $spacer !default;

$modal-footer-margin-between:       .5rem !default;

$modal-dialog-margin:               .5rem !default;
$modal-dialog-margin-y-sm-up:       1.75rem !default;

$modal-title-line-height:           $line-height-base !default;

$modal-content-color:               null !default;
$modal-content-bg:                  $white !default;
$modal-content-border-color:        var(--#{$prefix}border-color-translucent) !default;
$modal-content-border-width:        $border-width !default;
$modal-content-border-radius:       $border-radius-lg !default;
$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width) !default;
$modal-content-box-shadow-xs:       $box-shadow-sm !default;
$modal-content-box-shadow-sm-up:    $box-shadow-sm !default;

$modal-backdrop-bg:                 $gray-100 !default;
$modal-backdrop-opacity:            .6 !default;
$modal-backdrop-blur:               4px !default;
$modal-backdrop-grayscale:          0 !default;

$modal-header-border-color:         var(--#{$prefix}border-color) !default;
$modal-header-border-width:         $modal-content-border-width !default;
$modal-header-padding-y:            $modal-inner-padding !default;
$modal-header-padding-x:            $modal-inner-padding !default;
$modal-header-padding:              $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

$modal-footer-bg:                   null !default;
$modal-footer-border-color:         $modal-header-border-color !default;
$modal-footer-border-width:         $modal-header-border-width !default;

$modal-sm:                          300px !default;
$modal-md:                          500px !default;
$modal-lg:                          800px !default;
$modal-xl:                          1140px !default;

$modal-fade-transform:              translate(0, -50px) !default;
$modal-show-transform:              none !default;
$modal-transition:                  transform .3s ease-out !default;
$modal-scale-transform:             scale(1.02) !default;
// scss-docs-end modal-variables


// Alerts
//
// Define alert colors, border radius, and padding.

// scss-docs-start alert-variables
$alert-padding-y:               $spacer !default;
$alert-padding-x:               $spacer !default;
$alert-margin-bottom:           1rem !default;
$alert-border-radius:           $border-radius !default;
$alert-link-font-color:         $link-color !default;
$alert-link-font-weight:        $font-weight-base !default;
$alert-border-color:            $gray-800 !default;
$alert-border-width:            0 !default;
$alert-box-shadow:              $box-shadow-sm !default;
$alert-bg:                      $white;
$alert-dismissible-padding-r:   $alert-padding-x * 3 !default; // 3x covers width of x plus default padding on either side
// scss-docs-end alert-variables

// Notifications
//
// Define notification colors, border radius, and padding.

// scss-docs-start notification-variables
$notification-padding-y:               $spacer !default;
$notification-padding-x:               $spacer !default;
$notification-margin-bottom:           1rem !default;
$notification-border-radius:           $border-radius !default;
$notification-link-font-color:         $link-color !default;
$notification-link-font-weight:        $font-weight-base !default;
$notification-border-width:            0 !default;
$notification-bg:                      $gray-100 !default;
$notification-dismissible-padding-r:   $notification-padding-x * 3 !default; // 3x covers width of x plus default padding on either side
// scss-docs-end notification-variables

// Progress bars

// scss-docs-start progress-variables
$progress-height:                   .625rem !default;
$progress-font-size:                $font-size-base * .75 !default;
$progress-bg:                       $white !default;
$progress-border-radius:            5px !default;
$progress-border-color:             $gray-800 !default;
$progress-box-shadow:               null !default;
$progress-bar-color:                $white !default;
$progress-bar-bg:                   $primary !default;
$progress-bar-animation-timing:     1s linear infinite !default;
$progress-bar-transition:           width .6s ease !default;
// scss-docs-end progress-variables


// List group

// scss-docs-start list-group-variables
$list-group-color:                  $gray-900 !default;
$list-group-bg:                     $white !default;
$list-group-border-color:           rgba($black, .125) !default;
$list-group-border-width:           $border-width !default;
$list-group-border-radius:          $border-radius !default;

$list-group-item-padding-y:         $spacer * .5 !default;
$list-group-item-padding-x:         $spacer !default;
$list-group-item-bg-scale:          -80% !default;
$list-group-item-color-scale:       40% !default;

$list-group-hover-bg:               $gray-100 !default;
$list-group-active-color:           $component-active-color !default;
$list-group-active-bg:              $component-active-bg !default;
$list-group-active-border-color:    $list-group-active-bg !default;

$list-group-disabled-color:         $gray-1000 !default;
$list-group-disabled-bg:            $list-group-bg !default;

$list-group-action-color:           $gray-700 !default;
$list-group-action-hover-color:     $list-group-action-color !default;

$list-group-action-active-color:    $body-color !default;
$list-group-action-active-bg:       $gray-200 !default;
// scss-docs-end list-group-variables


// Image thumbnails

// scss-docs-start thumbnail-variables
$thumbnail-padding:                 .25rem !default;
$thumbnail-bg:                      $body-bg !default;
$thumbnail-border-width:            $border-width !default;
$thumbnail-border-color:            var(--#{$prefix}border-color) !default;
$thumbnail-border-radius:           $border-radius !default;
$thumbnail-box-shadow:              $box-shadow-sm !default;
// scss-docs-end thumbnail-variables


// Figures

// scss-docs-start figure-variables
$figure-caption-font-size:          $small-font-size !default;
$figure-caption-color:              $gray-1000 !default;
// scss-docs-end figure-variables


// Breadcrumbs

// scss-docs-start breadcrumb-variables
$breadcrumb-font-size:              null !default;
$breadcrumb-padding-y:              0 !default;
$breadcrumb-padding-x:              0 !default;
$breadcrumb-item-padding-x:         .5rem !default;
$breadcrumb-margin-bottom:          1rem !default;
$breadcrumb-bg:                     null !default;
$breadcrumb-divider-color:          $gray-800 !default;
$breadcrumb-active-color:           $body-color !default;
$breadcrumb-divider:                quote("/") !default;
$breadcrumb-divider-flipped:        $breadcrumb-divider !default;
$breadcrumb-border-radius:          null !default;
// scss-docs-end breadcrumb-variables

// Carousel

// scss-docs-start carousel-variables
$carousel-control-color:             $white !default;
$carousel-control-width:             15% !default;
$carousel-control-opacity:           .5 !default;
$carousel-control-hover-opacity:     .9 !default;
$carousel-control-transition:        opacity .15s ease !default;

$carousel-indicator-width:           30px !default;
$carousel-indicator-height:          3px !default;
$carousel-indicator-hit-area-height: 10px !default;
$carousel-indicator-spacer:          3px !default;
$carousel-indicator-opacity:         .5 !default;
$carousel-indicator-active-bg:       $white !default;
$carousel-indicator-active-opacity:  1 !default;
$carousel-indicator-transition:      opacity .6s ease !default;

$carousel-caption-width:             70% !default;
$carousel-caption-color:             $white !default;
$carousel-caption-padding-y:         1.25rem !default;
$carousel-caption-spacer:            1.25rem !default;

$carousel-control-icon-width:        2rem !default;

$carousel-control-prev-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>") !default;
$carousel-control-next-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>") !default;

$carousel-transition-duration:       .6s !default;
$carousel-transition:                transform $carousel-transition-duration ease-in-out !default; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)
// scss-docs-end carousel-variables

// scss-docs-start carousel-dark-variables
$carousel-dark-indicator-active-bg:  $black !default;
$carousel-dark-caption-color:        $black !default;
$carousel-dark-control-icon-filter:  invert(1) grayscale(100) !default;
// scss-docs-end carousel-dark-variables


// Spinners

// scss-docs-start spinner-variables
$spinner-width:               3.125rem !default;
$spinner-height:              $spinner-width !default;
$spinner-vertical-align:      -.125em !default;
$spinner-border-width:        .325em !default;
$spinner-animation-speed:     .95s !default;
$spinner-border-right-color:  $blue !default;

$spinner-width-sm:        1.25rem !default;
$spinner-height-sm:       $spinner-width-sm !default;
$spinner-border-width-sm: .125em !default;
// scss-docs-end spinner-variables


// Close

// scss-docs-start close-variables
$btn-close-width:            .734375em !default;
$btn-close-height:           $btn-close-width !default;
$btn-close-padding-x:        .25em !default;
$btn-close-padding-y:        $btn-close-padding-x !default;
$btn-close-color:            $gray-1200 !default;
$btn-close-bg:               url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>") !default;
$btn-close-focus-shadow:     $input-btn-focus-box-shadow !default;
$btn-close-opacity:          .5 !default;
$btn-close-hover-opacity:    .75 !default;
$btn-close-focus-opacity:    1 !default;
$btn-close-disabled-opacity: .25 !default;
$btn-close-white-filter:     invert(1) grayscale(100%) brightness(200%) !default;
// scss-docs-end close-variables


// Offcanvas

// scss-docs-start offcanvas-variables
$offcanvas-padding-y:               $modal-inner-padding !default;
$offcanvas-padding-x:               $modal-inner-padding !default;
$offcanvas-horizontal-width:        400px !default;
$offcanvas-vertical-height:         30vh !default;
$offcanvas-transition-duration:     .3s !default;
$offcanvas-border-color:            $modal-content-border-color !default;
$offcanvas-border-width:            $modal-content-border-width !default;
$offcanvas-title-line-height:       $modal-title-line-height !default;
$offcanvas-bg-color:                $modal-content-bg !default;
$offcanvas-color:                   $modal-content-color !default;
$offcanvas-box-shadow:              $modal-content-box-shadow-xs !default;
$offcanvas-backdrop-bg:             $modal-backdrop-bg !default;
$offcanvas-backdrop-opacity:        $modal-backdrop-opacity !default;
// scss-docs-end offcanvas-variables

// Code

$code-font-size:                    $small-font-size !default;
$code-color:                        $red !default;

$kbd-padding-y:                     .1875rem !default;
$kbd-padding-x:                     .375rem !default;
$kbd-font-size:                     $code-font-size !default;
$kbd-color:                         var(--#{$prefix}body-bg) !default;
$kbd-bg:                            var(--#{$prefix}body-color) !default;
$nested-kbd-font-weight:            null !default; // Deprecated in v5.2.0, removing in v6

$pre-color:                         null !default;

